import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { SwitchHorizontal01 } from '@untitled-ui/icons-react/build/esm';
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { usePopover } from '~/hooks/use-popover';
import { useDeleteProcess } from '~/queries/useDeleteProcess';
import { useUpdateProcess } from '~/queries/useUpdateProcess';
import { Process } from '~/types/__generated/gql/graphql';
import { ProcessModal } from './process-form';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
interface ProcessHeaderProps {
  process: Process;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}
export const ProcessHeader: FC<ProcessHeaderProps> = ({
  process,
  dragHandleProps
}) => {
  const {
    name,
    _files
  } = process;
  const updateProcess = useUpdateProcess();
  const deleteProcess = useDeleteProcess();
  const popover = usePopover<HTMLButtonElement>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [nameCopy, setNameCopy] = useState<string>(name);
  const [updateId, setUpdateId] = useState<string | null>(null);
  const onDelete = async (columnId: string): Promise<void> => {
    try {
      await deleteProcess.mutateAsync({
        id: columnId
      });
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };
  const onRename = async (columnId: string, newName: string): Promise<void> => {
    try {
      await updateProcess.mutateAsync({
        id: columnId,
        input: {
          name: newName
        }
      });
      inputRef?.current?.blur();
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };
  const handleNameReset = useCallback(() => {
    setNameCopy(name);
  }, [name]);
  useEffect(() => {
    handleNameReset();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [name]);
  const handleNameBlur = useCallback(() => {
    if (!nameCopy) {
      setNameCopy(name);
      return;
    }
    if (nameCopy === name) {
      return;
    }
    onRename(process._id, nameCopy);
  }, [nameCopy, name, onRename]);
  const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setNameCopy(event.target.value);
  }, []);
  const handleNameKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === 'Enter') {
      if (nameCopy && nameCopy !== name) {
        onRename(process._id, nameCopy);
      }
    }
  }, [nameCopy, name, onRename]);
  const handleDelete = useCallback((): void => {
    popover.handleClose();
    onDelete(process._id);
  }, [popover, onDelete]);
  return <>
			{!!updateId && <ProcessModal processId={updateId} onClose={() => setUpdateId(null)} />}
			<Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2} sx={{
      pr: 2,
      py: 1
    }} data-sentry-element="Stack" data-sentry-source-file="process-header.tsx">
				<IconButton {...dragHandleProps} edge="start" ref={popover.anchorRef} sx={{
        cursor: 'grab',
        ':active': {
          cursor: 'grabbing'
        }
      }} data-sentry-element="IconButton" data-sentry-source-file="process-header.tsx">
					<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="process-header.tsx">
						<SwitchHorizontal01 data-sentry-element="SwitchHorizontal01" data-sentry-source-file="process-header.tsx" />
					</SvgIcon>
				</IconButton>
				<Input disableUnderline fullWidth inputRef={inputRef} onBlur={handleNameBlur} onChange={handleNameChange} onKeyUp={handleNameKeyUp} placeholder="Process Name" sx={{
        '& .MuiInputBase-input': {
          borderRadius: 1.5,
          fontWeight: 500,
          overflow: 'hidden',
          px: 2,
          py: 1,
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          '&:hover, &:focus': {
            backgroundColor: theme => theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'
          }
        }
      }} value={nameCopy} data-sentry-element="Input" data-sentry-source-file="process-header.tsx" />
				<Stack alignItems="center" direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="process-header.tsx">
					<Chip label={_files.length} data-sentry-element="Chip" data-sentry-source-file="process-header.tsx" />
					<IconButton edge="end" onClick={popover.handleOpen} ref={popover.anchorRef} data-sentry-element="IconButton" data-sentry-source-file="process-header.tsx">
						<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="process-header.tsx">
							<DotsHorizontalIcon data-sentry-element="DotsHorizontalIcon" data-sentry-source-file="process-header.tsx" />
						</SvgIcon>
					</IconButton>
				</Stack>
			</Stack>
			<Menu anchorEl={popover.anchorRef.current} transformOrigin={{
      horizontal: 'right',
      vertical: 'top'
    }} anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom'
    }} keepMounted onClose={popover.handleClose} open={popover.open} data-sentry-element="Menu" data-sentry-source-file="process-header.tsx">
				<MenuItem onClick={() => {
        popover.handleClose();
        setUpdateId(process._id);
      }} data-sentry-element="MenuItem" data-sentry-source-file="process-header.tsx">
					Update
				</MenuItem>
				<MenuItem onClick={handleDelete} data-sentry-element="MenuItem" data-sentry-source-file="process-header.tsx">Delete</MenuItem>
			</Menu>
		</>;
};