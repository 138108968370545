import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { UpdateProcessMutation, UpdateProcessMutationVariables } from '~/types/__generated/gql/graphql';
import { getProcessesQueryKey, GetProcessesResults, sortProcessesAndFiles } from '../useGetProcesses';

const query = /* GraphQL */ `
	mutation UpdateProcess($id: ObjectID!, $input: UpdateProcessInput!) {
		updateProcess(id: $id, input: $input) {
			leadTime
			name
			sortingIndex
			_id
			createdAt
			updatedAt
			_files {
				customFields {
					customField
					textValue
					selectValues
					dateTimeValue
				}
				processPaths {
					process
					leadTime
				}
				processLastUpdatedAt
				isProcessed
				products {
					product
					metadata {
						originalPrice
						originalPriceAddons {
							vat {
								percentage
								amount
							}
							serviceCharge {
								percentage
								amount
							}
						}
						listedPrice
					}
					quantity
					options {
						option
						metadata {
							originalPrice
							originalPriceAddons {
								vat {
									percentage
									amount
								}
								serviceCharge {
									percentage
									amount
								}
							}
							listedPrice
						}
						quantity
						_id
						createdAt
						updatedAt

						_option {
							_id
							name
						}
					}
					_id
					createdAt
					updatedAt

					_product {
						_id
						name
						description
						category
						type
					}
				}
				sortingIndex
				counterConfigs {
					index

					_counter {
						_id
						createdAt
						updatedAt
						prefix
					}
				}
				_id
				createdAt
				updatedAt

				_process {
					name
					_id
					createdAt
					updatedAt
				}
				_user {
					firstName
					lastName
					avatar
					_id
					createdAt
					updatedAt
				}
				_client {
					name
					_id
					createdAt
					updatedAt
				}
				_agent {
					name
					_id
					createdAt
					updatedAt
				}
				_assignees {
					firstName
					lastName
					avatar
					_id
					createdAt
					updatedAt
				}
			}
		}
	}
`;

export type UpdateProcessVariables = UpdateProcessMutationVariables;
export type UpdateProcessResults = UpdateProcessMutation['updateProcess'];

export const useUpdateProcess = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (variables: UpdateProcessVariables) =>
			apiGqlClient.request(graphql(query), variables).then((res) => res.updateProcess),
		onSuccess: async (data) => {
			queryClient.setQueryData<GetProcessesResults>(getProcessesQueryKey(), (oldData) =>
				oldData ? sortProcessesAndFiles(oldData.map((o) => (o._id === data._id ? data : o))) : [data]
			);
		},
	});
};
