import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, Fragment } from 'react';
import { formatCurrency } from '~/utils/format-currency';
export type TableItemOption = {
  id: string;
  name: string;
  quantity: number;
  subtotal: number;
};
export type TableItem = {
  id: string;
  name: string;
  quantity: number;
  subtotal: number;
  options: Array<TableItemOption>;
};
export type ProductsTableSummaryProps = {
  grandTotal: number;
  items: Array<TableItem>;
  onRowClick?: (item: TableItem) => void;
};
export const ProductsTableSummary: FC<ProductsTableSummaryProps> = ({
  items,
  grandTotal,
  onRowClick
}) => {
  return <Table data-sentry-element="Table" data-sentry-component="ProductsTableSummary" data-sentry-source-file="products-table-summary.tsx">
			<TableHead data-sentry-element="TableHead" data-sentry-source-file="products-table-summary.tsx">
				<TableRow data-sentry-element="TableRow" data-sentry-source-file="products-table-summary.tsx">
					<TableCell data-sentry-element="TableCell" data-sentry-source-file="products-table-summary.tsx">#</TableCell>
					<TableCell data-sentry-element="TableCell" data-sentry-source-file="products-table-summary.tsx">Description</TableCell>
					<TableCell align="right" data-sentry-element="TableCell" data-sentry-source-file="products-table-summary.tsx">Total</TableCell>
				</TableRow>
			</TableHead>
			<TableBody data-sentry-element="TableBody" data-sentry-source-file="products-table-summary.tsx">
				{items.map((item, index) => <Fragment key={item.id}>
						<TableRow hover={!!onRowClick} sx={{
          cursor: onRowClick ? 'pointer' : 'unset'
        }} onClick={() => onRowClick?.(item)}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>
								<b>{item.quantity}x</b> {item.name}
								<br />
								{item.options.map(option => <Typography variant="caption" component="div" key={option.id} color="text.secondary">
										{option.quantity}x {option.name}
									</Typography>)}
							</TableCell>
							<TableCell align="right">{formatCurrency(item.subtotal)}</TableCell>
						</TableRow>
					</Fragment>)}
				<TableRow data-sentry-element="TableRow" data-sentry-source-file="products-table-summary.tsx">
					<TableCell colSpan={1} sx={{
          borderBottom: 'none'
        }} data-sentry-element="TableCell" data-sentry-source-file="products-table-summary.tsx" />
					<TableCell align="right" sx={{
          borderBottom: 'none'
        }} data-sentry-element="TableCell" data-sentry-source-file="products-table-summary.tsx">
						<Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="products-table-summary.tsx">Grand Total</Typography>
					</TableCell>
					<TableCell align="right" sx={{
          borderBottom: 'none'
        }} data-sentry-element="TableCell" data-sentry-source-file="products-table-summary.tsx">
						<Typography variant="subtitle2" data-sentry-element="Typography" data-sentry-source-file="products-table-summary.tsx">{formatCurrency(grandTotal)}</Typography>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>;
};