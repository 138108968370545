import { AppBar, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, Unstable_Grid2 as Grid, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { MinusCircle, PlusCircle } from '@untitled-ui/icons-react/build/esm';
import { ObjectID } from 'bson';
import { pick } from '~served/utils';
import { useTillSelectedProduct } from '~/hooks/use-till-selected-item';
import { GetTillProductCategoriesResults } from '~/queries/useGetTillProductCategories';
import { useTill, useUpdateTillCreateFileInput, useUpdateTillTempProductInput, useUpdateTillUpdateFileInput } from '~/store';
import { FileProductOptionInput } from '~/types/__generated/gql/graphql';
import { formatCurrency } from '~/utils/format-currency';
import { useGetProducts } from '~/queries/useGetProducts';
export const TempProductModal = () => {
  const {
    data: products = []
  } = useGetProducts();
  const {
    tempProductInput,
    createFileInput,
    updateFileInput
  } = useTill();
  const updateTempProduct = useUpdateTillTempProductInput();
  const updateCreateFileInput = useUpdateTillCreateFileInput();
  const updateUpdateFileInput = useUpdateTillUpdateFileInput();
  const selectedProduct = useTillSelectedProduct();
  const handleClose = async () => {
    console.log('🚀 ~ handleClose ~ tempProductInput:', tempProductInput);
    console.log('🚀 ~ handleClose ~ createFileInput:', createFileInput);
    console.log('🚀 ~ handleClose ~ updateFileInput:', updateFileInput);
    if (createFileInput && tempProductInput) {
      if (tempProductInput.quantity === 0) {
        updateCreateFileInput({
          products: createFileInput.products.filter(p => p._id !== tempProductInput._id)
        });
      } else {
        const isAlreadyAdded = createFileInput!.products.some(p => p._id === tempProductInput._id);
        if (isAlreadyAdded) {
          updateCreateFileInput({
            products: createFileInput!.products.map(p => p._id === tempProductInput._id ? tempProductInput : p)
          });
        } else {
          updateCreateFileInput({
            products: [...createFileInput!.products, tempProductInput]
          });
        }
      }
    }
    if (updateFileInput && tempProductInput) {
      if (tempProductInput.quantity === 0) {
        updateUpdateFileInput({
          products: updateFileInput.products.filter(p => p._id !== tempProductInput._id)
        });
      } else {
        const isAlreadyAdded = updateFileInput!.products.some(p => p._id === tempProductInput._id);
        if (isAlreadyAdded) {
          updateUpdateFileInput({
            products: updateFileInput.products.map(p => p._id === tempProductInput._id ? tempProductInput : p)
          });
        } else {
          updateUpdateFileInput({
            products: [...updateFileInput!.products, tempProductInput]
          });
        }
      }
    }
    updateTempProduct(null);
  };
  const onOptionClick = (option: GetTillProductCategoriesResults[number]['_products'][number]['_options'][number]) => {
    const isAlreadyAdded = tempProductInput!.options.some(o => o.option === option._id);
    if (isAlreadyAdded) {
      const newOptions = tempProductInput!.options.map(o => o.option === option._id ? {
        ...o,
        quantity: o.quantity + 1
      } : o);
      updateTempProduct({
        options: newOptions
      });
    } else {
      const newOption: FileProductOptionInput = {
        _id: new ObjectID().toString(),
        option: option._id,
        quantity: 1,
        metadata: pick(option, ['listedPrice', 'originalPrice', 'originalPriceAddons']) as FileProductOptionInput['metadata']
      };
      updateTempProduct({
        options: [...tempProductInput!.options, newOption]
      });
    }
  };
  const onIncreaseOptionQuantity = (optionId: string) => {
    const newOptions = tempProductInput!.options.map(o => o._id === optionId ? {
      ...o,
      quantity: o.quantity + 1
    } : o);
    updateTempProduct({
      options: newOptions
    });
  };
  const onDecreaseOptionQuantity = (optionId: string) => {
    const newOptions = tempProductInput!.options.map(o => o._id === optionId ? {
      ...o,
      quantity: o.quantity - 1
    } : o).filter(o => o.quantity > 0);
    updateTempProduct({
      options: newOptions
    });
  };
  const onDecreaseItemQuantity = () => {
    updateTempProduct({
      quantity: tempProductInput!.quantity - 1
    });
  };
  const onIncreaseItemQuantity = () => {
    updateTempProduct({
      quantity: tempProductInput!.quantity + 1
    });
  };
  return <Dialog fullWidth maxWidth="md" scroll="paper" open={!!tempProductInput} data-sentry-element="Dialog" data-sentry-component="TempProductModal" data-sentry-source-file="temp-product-modal.tsx">
			<Box sx={{
      height: '100dvh',
      overflow: 'hidden'
    }} data-sentry-element="Box" data-sentry-source-file="temp-product-modal.tsx">
				<AppBar color="inherit" sx={{
        position: 'relative'
      }} data-sentry-element="AppBar" data-sentry-source-file="temp-product-modal.tsx">
					<Toolbar data-sentry-element="Toolbar" data-sentry-source-file="temp-product-modal.tsx">
						<Typography sx={{
            ml: 2,
            flex: 1
          }} variant="h6" component="div" data-sentry-element="Typography" data-sentry-source-file="temp-product-modal.tsx">
							{products.find(p => p._id === tempProductInput?.product)?.name}
						</Typography>
						<Stack direction="row" alignItems="center" data-sentry-element="Stack" data-sentry-source-file="temp-product-modal.tsx">
							<IconButton color="inherit" disabled={tempProductInput?.quantity === 0} onClick={onDecreaseItemQuantity} data-sentry-element="IconButton" data-sentry-source-file="temp-product-modal.tsx">
								<MinusCircle data-sentry-element="MinusCircle" data-sentry-source-file="temp-product-modal.tsx" />
							</IconButton>
							<Chip label={tempProductInput?.quantity || 0} variant="filled" data-sentry-element="Chip" data-sentry-source-file="temp-product-modal.tsx" />
							<IconButton color="inherit" onClick={onIncreaseItemQuantity} data-sentry-element="IconButton" data-sentry-source-file="temp-product-modal.tsx">
								<PlusCircle data-sentry-element="PlusCircle" data-sentry-source-file="temp-product-modal.tsx" />
							</IconButton>
						</Stack>
						<Button sx={{
            ml: 2
          }} variant="contained" onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="temp-product-modal.tsx">
							Done
						</Button>
					</Toolbar>
				</AppBar>
				<Grid container spacing={3} p={3} data-sentry-element="Grid" data-sentry-source-file="temp-product-modal.tsx">
					{!!selectedProduct && selectedProduct._options.map(option => {
          const addedOption = tempProductInput?.options?.find(o => o.option === option._id);
          return <Grid key={option._id} xs={6} md={4}>
									<Card variant="outlined" sx={[{
              cursor: 'pointer',
              ':hover': {
                boxShadow: t => t.shadows[8]
              }
            }, !!addedOption && {
              boxShadow: t => t.shadows[8],
              borderColor: 'primary.main',
              backgroundColor: 'primary.main',
              color: t => t.palette.primary.contrastText,
              '.MuiCardHeader-subheader': {
                color: t => t.palette.primary.contrastText
              }
            }]} onClick={() => onOptionClick(option)}>
										<CardHeader title={option.name} subheader={formatCurrency(option.listedPrice)} action={addedOption ? <Stack direction="row" alignItems="center">
														<IconButton color="inherit" onClick={e => {
                  e.stopPropagation();
                  onDecreaseOptionQuantity(addedOption._id);
                }}>
															<MinusCircle />
														</IconButton>
														<Chip label={addedOption.quantity} variant="filled" sx={{
                  color: t => t.palette.primary.contrastText
                }} />
														<IconButton color="inherit" onClick={e => {
                  e.stopPropagation();
                  onIncreaseOptionQuantity(addedOption._id);
                }}>
															<PlusCircle />
														</IconButton>
													</Stack> : null} />
										<CardContent />
										<CardActions />
									</Card>
								</Grid>;
        })}

					{!!selectedProduct && !selectedProduct._options.length && !selectedProduct.configuredOptionGroups.length && <Grid xs={12} width="100%" textAlign="center">
								<Box alt="Not found" component="img" src="/assets/errors/error-404.png" sx={{
            height: 'auto',
            maxWidth: '100%',
            width: '200px',
            mx: 'auto',
            mb: 2
          }} />
								<Typography variant="h6">This product has no options</Typography>
							</Grid>}
				</Grid>
			</Box>
		</Dialog>;
};