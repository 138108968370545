import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Autocomplete, Button, Chip, Dialog, FormHelperText, MenuItem, OutlinedInput, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { BarChartSquarePlus, Pencil01, Route } from '@untitled-ui/icons-react/build/esm';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useBoolean } from 'usehooks-ts';
import { CustomFieldRenderer, CustomFieldsDialog } from '~/components/custom-fields-dialog';
import { ProductsTableSummary } from '~/components/products-table-summary';
import { useAppRouter } from '~/hooks/use-app-router';
import { useTillSummary } from '~/hooks/use-till-summary';
import { useCreateFile } from '~/queries/useCreateFile';
import { useGetAgents } from '~/queries/useGetAgents';
import { useGetClients } from '~/queries/useGetClients';
import { useGetCounters } from '~/queries/useGetCounters';
import { useGetCurrentCompanyCache } from '~/queries/useGetCurrentCompany';
import { useGetFileCustomFields } from '~/queries/useGetCustomFields';
import { useGetProcessesCache } from '~/queries/useGetProcesses';
import { useGetUsers } from '~/queries/useGetUsers';
import { useMeCache } from '~/queries/useMe';
import { useResetTill, useSetTillIsOpen, useTill, useUpdateTillCreateFileInput } from '~/store';
import { CreateFileInput, CUSTOM_FIELD_MODEL, CustomFieldValueInput } from '~/types/__generated/gql/graphql';
import { DefineProcessPathsDialog } from './define-process-paths-dialog';
import { FileStatus } from './file-status';
export const CreateFileModal = () => {
  const {
    appQuery,
    currentActions: {
      isCreatingFile
    },
    router,
    goToFileDetailsQuery
  } = useAppRouter();
  const {
    data: company
  } = useGetCurrentCompanyCache();
  const {
    data: me
  } = useMeCache();
  const {
    data: processes = []
  } = useGetProcessesCache();
  const setTillIsOpen = useSetTillIsOpen();
  const till = useTill();
  const resetTill = useResetTill();
  const updateCreateFileInput = useUpdateTillCreateFileInput();
  const createFile = useCreateFile();
  const {
    data: agents = []
  } = useGetAgents();
  const {
    data: clients = []
  } = useGetClients();
  const {
    createFileInputSummary
  } = useTillSummary();
  const {
    data: users = []
  } = useGetUsers();
  const {
    data: counters = []
  } = useGetCounters();
  const {
    value: isProcessPathsDialogOpen,
    setTrue: openProcessPathsDialog,
    setFalse: closeProcessPathsDialog
  } = useBoolean(false);
  const {
    value: isCustomFieldsDialogOpen,
    setTrue: openCustomFieldsDialog,
    setFalse: closeCustomFieldsDialog
  } = useBoolean(false);
  const {
    data: customFields = []
  } = useGetFileCustomFields();
  const [customFieldValues, setCustomFieldValues] = useState<Record<string, CustomFieldValueInput>>({});
  const processOptions = useMemo(() => processes.map(column => ({
    label: column.name,
    value: column._id
  })), [processes]);
  const onClose = router.back;
  const handleCreateFileInColumn = async (columnId: string): Promise<void> => {
    const newFile = await createFile.mutateAsync({
      input: {
        ...till.createFileInput!,
        process: columnId,
        sortingIndex: processes.find(process => process._id === columnId)?._files?.length || 0,
        customFields: Object.values(customFieldValues)
      }
    });
    toast.success(`New ${company?.fileAlias?.singular} created`);
    goToFileDetailsQuery(newFile._id);
  };
  useEffect(() => {
    if (isCreatingFile) {
      updateCreateFileInput({
        process: appQuery.processId,
        products: [],
        processPaths: []
      });
    }
    return () => {
      if (isCreatingFile) {
        resetTill();
        setCustomFieldValues({});
      }
    };
  }, [isCreatingFile, appQuery.processId]);
  return <Dialog fullWidth maxWidth="xl" scroll="paper" open={isCreatingFile} data-sentry-element="Dialog" data-sentry-component="CreateFileModal" data-sentry-source-file="create-file-modal.tsx">
			{isCustomFieldsDialogOpen && <CustomFieldsDialog model={CUSTOM_FIELD_MODEL.FILE} onClose={closeCustomFieldsDialog} />}

			<Stack gap={3} p={3} sx={{
      height: '100dvh',
      overflow: 'auto'
    }} data-sentry-element="Stack" data-sentry-source-file="create-file-modal.tsx">
				{isProcessPathsDialogOpen && <DefineProcessPathsDialog initialValues={till.createFileInput?.processPaths || []} onDone={values => {
        updateCreateFileInput({
          processPaths: values as CreateFileInput['processPaths']
        });
        closeProcessPathsDialog();
      }} onCancel={closeProcessPathsDialog} />}
				<Stack alignItems={{
        sm: 'center'
      }} direction={{
        xs: 'column-reverse',
        sm: 'row'
      }} justifyContent={{
        sm: 'space-between'
      }} spacing={1} data-sentry-element="Stack" data-sentry-source-file="create-file-modal.tsx">
					<div>
						{till.createFileInput?.processPaths?.length ? <LoadingButton loading={createFile.isLoading} variant="contained" onClick={() => handleCreateFileInColumn(till.createFileInput!.processPaths[0].process)}>
								Create in&nbsp;
								{processes.find(process => process._id === till.createFileInput!.processPaths[0].process)?.name}
							</LoadingButton> : <FileStatus prefix="Create in" onChange={columnId => handleCreateFileInColumn(columnId)} isLoading={createFile.isLoading} options={processOptions} value={appQuery.processId || processOptions[0]?.value} />}
					</div>

					<Stack justifyContent="flex-end" alignItems="center" direction="row" spacing={1} data-sentry-element="Stack" data-sentry-source-file="create-file-modal.tsx">
						<IconButton onClick={onClose} data-sentry-element="IconButton" data-sentry-source-file="create-file-modal.tsx">
							<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="create-file-modal.tsx">
								<XIcon data-sentry-element="XIcon" data-sentry-source-file="create-file-modal.tsx" />
							</SvgIcon>
						</IconButton>
					</Stack>
				</Stack>
				<Box data-sentry-element="Box" data-sentry-source-file="create-file-modal.tsx">
					<Stack spacing={2} direction="row" data-sentry-element="Stack" data-sentry-source-file="create-file-modal.tsx">
						<TextField select label="Prefix" sx={{
            width: '150px'
          }} onChange={e => {
            updateCreateFileInput({
              counterConfigs: {
                counter: e.target.value
              }
            });
          }} data-sentry-element="TextField" data-sentry-source-file="create-file-modal.tsx">
							{counters.map(counter => <MenuItem key={counter._id} value={counter._id}>
									{counter.prefix}
								</MenuItem>)}
						</TextField>
						<OutlinedInput disabled fullWidth placeholder="Prefix index goes here" data-sentry-element="OutlinedInput" data-sentry-source-file="create-file-modal.tsx" />
					</Stack>
					<FormHelperText data-sentry-element="FormHelperText" data-sentry-source-file="create-file-modal.tsx">
						If you select a prefix, prefix index will be generated once you created this&nbsp;
						{company?.fileAlias?.singular || ''}
					</FormHelperText>
					<Divider data-sentry-element="Divider" data-sentry-source-file="create-file-modal.tsx" />
				</Box>
				<Grid container spacing={3} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
					<Grid container spacing={3} xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
						<Grid xs={12} sm={4} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Typography color="text.secondary" variant="caption" data-sentry-element="Typography" data-sentry-source-file="create-file-modal.tsx">
								Created by
							</Typography>
						</Grid>
						<Grid xs={12} sm={8} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Stack direction="row" alignItems="center" spacing={1} data-sentry-element="Stack" data-sentry-source-file="create-file-modal.tsx">
								<Avatar src={me?.avatar || undefined} data-sentry-element="Avatar" data-sentry-source-file="create-file-modal.tsx" />
								<Typography variant="caption" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="create-file-modal.tsx">
									{me?.firstName}
								</Typography>
							</Stack>
						</Grid>
						<Grid xs={12} sm={4} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Typography color="text.secondary" variant="caption" data-sentry-element="Typography" data-sentry-source-file="create-file-modal.tsx">
								Process paths
							</Typography>
						</Grid>
						<Grid xs={12} sm={8} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							{!!till.createFileInput?.processPaths?.length && <Timeline sx={{
              m: 0,
              py: 0
            }}>
									{till.createFileInput.processPaths.slice(0, 3).map((path, index, current) => {
                const processData = processes.find(process => process._id === path.process);
                if (!processData) {
                  return null;
                }
                return <TimelineItem key={index} sx={{
                  '::before': {
                    content: 'unset'
                  }
                }}>
												<TimelineSeparator>
													<TimelineDot color={index === 0 ? 'primary' : 'grey'} />
													{index < 2 && !!current[index + 1] && <TimelineConnector />}
													{index === 2 && !!till.createFileInput!.processPaths[3] && <TimelineConnector />}
												</TimelineSeparator>
												<TimelineContent>{processData.name}</TimelineContent>
											</TimelineItem>;
              })}
								</Timeline>}

							<Button startIcon={<SvgIcon>
										<Route />
									</SvgIcon>} onClick={openProcessPathsDialog} data-sentry-element="Button" data-sentry-source-file="create-file-modal.tsx">
								Define process paths
							</Button>
						</Grid>
						<Grid xs={12} sm={4} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Typography color="text.secondary" variant="caption" data-sentry-element="Typography" data-sentry-source-file="create-file-modal.tsx">
								Assignees
							</Typography>
						</Grid>
						<Grid xs={12} sm={8} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Autocomplete multiple fullWidth disableCloseOnSelect options={users} value={users.filter(user => till.createFileInput?.assignees?.includes(user._id))} isOptionEqualToValue={(option, value) => option._id === value._id} getOptionLabel={option => option.firstName} onChange={(_, values) => {
              updateCreateFileInput({
                assignees: values.map(user => user._id)
              });
            }} renderInput={params => <TextField {...params} label="Users" />} renderTags={(value, getTagProps) => value.map((value, index) => <Chip {...getTagProps({
              index
            })} key={value._id} label={value.firstName} avatar={<Avatar src={value.avatar || undefined} />} />)} renderOption={(props, option) => <li {...props}>
										<Avatar src={option.avatar || undefined} />
										&nbsp;{option.firstName}
									</li>} data-sentry-element="Autocomplete" data-sentry-source-file="create-file-modal.tsx" />
						</Grid>
						<Grid xs={12} sm={4} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Typography color="text.secondary" variant="caption" data-sentry-element="Typography" data-sentry-source-file="create-file-modal.tsx">
								Client
							</Typography>
						</Grid>
						<Grid xs={12} sm={8} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Autocomplete fullWidth options={clients} value={clients.find(client => client._id === till.createFileInput?.client)} isOptionEqualToValue={(option, value) => option._id === value._id} getOptionLabel={option => option.name} onChange={(_, value) => updateCreateFileInput({
              client: value?._id
            })} renderInput={params => <TextField {...params} label="Clients" error={!till.createFileInput?.client} helperText={till.createFileInput?.client ? null : 'Required'} />} data-sentry-element="Autocomplete" data-sentry-source-file="create-file-modal.tsx" />
						</Grid>
						<Grid xs={12} sm={4} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Typography color="text.secondary" variant="caption" data-sentry-element="Typography" data-sentry-source-file="create-file-modal.tsx">
								Agent
							</Typography>
						</Grid>
						<Grid xs={12} sm={8} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Autocomplete fullWidth options={agents} value={agents.find(agent => agent._id === till.createFileInput?.agent)} isOptionEqualToValue={(option, value) => option._id === value._id} getOptionLabel={option => option.name} onChange={(_, value) => updateCreateFileInput({
              agent: value?._id
            })} renderInput={params => <TextField {...params} label="Agents" />} data-sentry-element="Autocomplete" data-sentry-source-file="create-file-modal.tsx" />
						</Grid>
						{customFields.map(field => [<Grid xs={12} sm={4} key={`${field._id}-label`}>
								<Typography color="text.secondary" variant="caption">
									{field.name}
								</Typography>
							</Grid>, <Grid xs={12} sm={8} key={`${field._id}-input`}>
								<CustomFieldRenderer field={field} value={customFieldValues[field._id]} onChange={async value => {
              const updatedCustomFieldValues = {
                ...customFieldValues,
                [value.customField]: value
              };
              setCustomFieldValues(updatedCustomFieldValues);
            }} />
							</Grid>])}
						<Grid xs={12} textAlign="right" data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Button startIcon={<BarChartSquarePlus />} onClick={openCustomFieldsDialog} data-sentry-element="Button" data-sentry-source-file="create-file-modal.tsx">
								Manage custom fields
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={3} xs={12} md={6} flexDirection="column" data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
						<Grid xs={12} textAlign="right" data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<Button onClick={() => {
              setTillIsOpen(true);
            }} startIcon={<SvgIcon>
										<Pencil01 />
									</SvgIcon>} data-sentry-element="Button" data-sentry-source-file="create-file-modal.tsx">
								Update products
							</Button>
						</Grid>
						<Grid xs={12} data-sentry-element="Grid" data-sentry-source-file="create-file-modal.tsx">
							<ProductsTableSummary items={createFileInputSummary.items} grandTotal={createFileInputSummary.grandTotal} data-sentry-element="ProductsTableSummary" data-sentry-source-file="create-file-modal.tsx" />
						</Grid>
					</Grid>
				</Grid>
			</Stack>
		</Dialog>;
};