import Box from '@mui/material/Box';
import { FC } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Skeleton, Stack } from '@mui/material';
import { Process } from '~/types/__generated/gql/graphql';
import { FileCreate } from '../file-create';
import { FileCard } from '../file-card';
import { ProcessHeader } from './process-header';
interface ProcessCardProps {
  process: Process;
}
export const ProcessCard: FC<ProcessCardProps> = ({
  process
}) => {
  return <Draggable draggableId={process._id} index={process.sortingIndex} data-sentry-element="Draggable" data-sentry-component="ProcessCard" data-sentry-source-file="process-card.tsx">
			{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    @ts-ignore */}
			{(processDraggableProvided, processDraggableSnapshot) => <Box ref={processDraggableProvided.innerRef} style={{
      ...processDraggableProvided.draggableProps.style
    }} {...processDraggableProvided.draggableProps} sx={[{
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',
      width: {
        xs: 300,
        sm: 380
      }
    }, processDraggableSnapshot.isDragging && {}]}>
					<ProcessHeader process={process} dragHandleProps={processDraggableProvided.dragHandleProps} />
					<Box sx={{
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'neutral.900' : 'neutral.100',
        borderRadius: 2.5
      }}>
						<Box sx={{
          pt: 3,
          px: 3
        }}>
							<FileCreate process={process} />
						</Box>
						<Droppable type="FILE" droppableId={process._id}>
							{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        @ts-ignore */}
							{fileDroppableProvider => <Box ref={fileDroppableProvider.innerRef} sx={{
            flexGrow: 1,
            minHeight: 80,
            overflowY: 'auto',
            px: 3,
            pt: 1.5,
            pb: 1.5
          }}>
									{process._files.map(file => <Draggable key={file._id} draggableId={file._id} index={file.sortingIndex}>
											{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            @ts-ignore */}
											{(fileDraggableProvided, fileDraggableSnapshot) => <Box ref={fileDraggableProvided.innerRef} style={{
                ...fileDraggableProvided.draggableProps.style
              }} sx={{
                outline: 'none',
                py: 1.5
              }} {...fileDraggableProvided.draggableProps} {...fileDraggableProvided.dragHandleProps}>
													<FileCard key={file._id} dragging={fileDraggableSnapshot.isDragging} file={file} />
												</Box>}
										</Draggable>)}
									{fileDroppableProvider.placeholder}
								</Box>}
						</Droppable>
					</Box>
				</Box>}
		</Draggable>;
};
export const ProcessLoadingCard = () => {
  return <Box sx={[{
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: {
      xs: 300,
      sm: 380
    }
  }]} data-sentry-element="Box" data-sentry-component="ProcessLoadingCard" data-sentry-source-file="process-card.tsx">
			<Stack direction="row" spacing={2} py={1} data-sentry-element="Stack" data-sentry-source-file="process-card.tsx">
				<Skeleton variant="rounded" sx={{
        flexGrow: 1,
        height: '40px'
      }} data-sentry-element="Skeleton" data-sentry-source-file="process-card.tsx" />
			</Stack>
			<Stack spacing={3} mt={3} data-sentry-element="Stack" data-sentry-source-file="process-card.tsx">
				<Skeleton variant="rounded" sx={{
        height: '180px'
      }} data-sentry-element="Skeleton" data-sentry-source-file="process-card.tsx" />
				<Skeleton variant="rounded" sx={{
        height: '180px'
      }} data-sentry-element="Skeleton" data-sentry-source-file="process-card.tsx" />
				<Skeleton variant="rounded" sx={{
        height: '180px'
      }} data-sentry-element="Skeleton" data-sentry-source-file="process-card.tsx" />
			</Stack>
		</Box>;
};