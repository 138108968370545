import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { BulkUpdateProcessesMutation, BulkUpdateProcessesMutationVariables, BulkUpdateProcessInput } from '~/types/__generated/gql/graphql';
import { getProcessesQueryKey, GetProcessesResults, sortProcessesAndFiles } from '../useGetProcesses';
const query = /* GraphQL */`
	mutation BulkUpdateProcesses($inputs: [BulkUpdateProcessInput!]!) {
		bulkUpdateProcesses(inputs: $inputs)
	}
`;
export type BulkUpdateProcessesVariables = BulkUpdateProcessesMutationVariables;
export type BulkUpdateProcessesResults = BulkUpdateProcessesMutation['bulkUpdateProcesses'];
export const useBulkUpdateProcesses = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: BulkUpdateProcessesVariables) => apiGqlClient.request(graphql(query), variables).then(res => res.bulkUpdateProcesses),
    onMutate: async variables => {
      queryClient.setQueryData<GetProcessesResults>(getProcessesQueryKey(), prev => {
        if (!prev) return [];
        const newData = prev.map(process => {
          const updatedProcess = (variables.inputs as BulkUpdateProcessInput[]).find(input => input.id === process._id);
          if (updatedProcess) {
            return {
              ...process,
              ...updatedProcess.input
            };
          }
          return process;
        });
        return sortProcessesAndFiles(newData);
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(getProcessesQueryKey());
    }
  });
};