import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Autocomplete, Button, Chip, Dialog, FormHelperText, MenuItem, OutlinedInput, Skeleton, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { BarChartSquarePlus, Check, ChevronRight, Pencil01, Route } from '@untitled-ui/icons-react/build/esm';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { pick } from '~served/utils';
import { CustomFieldRenderer, CustomFieldsDialog } from '~/components/custom-fields-dialog';
import { ProductsTableSummary } from '~/components/products-table-summary';
import { useAppRouter } from '~/hooks/use-app-router';
import { useTillSummary } from '~/hooks/use-till-summary';
import { $dayjs } from '~/libs/dayjs';
import { useGetAgents } from '~/queries/useGetAgents';
import { useGetClients } from '~/queries/useGetClients';
import { useGetCounters } from '~/queries/useGetCounters';
import { useGetFileCustomFields } from '~/queries/useGetCustomFields';
import { useGetCurrentFile } from '~/queries/useGetFileById';
import { useGetProcessesCache } from '~/queries/useGetProcesses';
import { useGetUsers } from '~/queries/useGetUsers';
import { useUpdateFile } from '~/queries/useUpdateFile';
import { useResetTill, useSetTillIsOpen, useTill, useUpdateTillUpdateFileInput } from '~/store';
import { CUSTOM_FIELD_MODEL, CustomFieldValueInput, UpdateFileInput } from '~/types/__generated/gql/graphql';
import { DefineProcessPathsDialog } from './define-process-paths-dialog';
import { FileStatus } from './file-status';
export const ViewFileModal = () => {
  const {
    currentActions: {
      isViewingFile
    },
    router
  } = useAppRouter();
  const {
    data: processes = []
  } = useGetProcessesCache();
  const {
    data: file,
    isLoading
  } = useGetCurrentFile();
  const till = useTill();
  const setTillIsOpen = useSetTillIsOpen();
  const resetTill = useResetTill();
  const updateUpdateFileInput = useUpdateTillUpdateFileInput();
  const {
    data: agents = []
  } = useGetAgents();
  const {
    data: clients = []
  } = useGetClients();
  const {
    data: users = []
  } = useGetUsers();
  const {
    data: counters = []
  } = useGetCounters();
  const {
    updateFileInputSummary
  } = useTillSummary();
  const updateFile = useUpdateFile();
  const {
    value: isProcessPathsDialogOpen,
    setTrue: openProcessPathsDialog,
    setFalse: closeProcessPathsDialog
  } = useBoolean(false);
  const {
    value: isFileMoving,
    toggle: toggleFileMoving
  } = useBoolean(false);
  const {
    value: isFileProcessing,
    toggle: toggleFileProcessing
  } = useBoolean(false);
  const {
    value: isCustomFieldsDialogOpen,
    setTrue: openCustomFieldsDialog,
    setFalse: closeCustomFieldsDialog
  } = useBoolean(false);
  const {
    data: customFields = []
  } = useGetFileCustomFields();
  const [customFieldValues, setCustomFieldValues] = useState<Record<string, CustomFieldValueInput>>({});
  const nextProcessId = useMemo(() => {
    if (!file || !file.processPaths.length) return;
    const currentProcessIndex = file.processPaths.findIndex(path => path._process._id === file._process._id);
    return file.processPaths[currentProcessIndex + 1]?._process?._id;
  }, [file]);
  const processOptions = useMemo(() => processes.map(column => ({
    label: column.name,
    value: column._id
  })), [processes]);
  const onClose = router.back;
  const handleMoveFileToColumn = async (columnId: string): Promise<void> => {
    try {
      toggleFileMoving();
      await updateFile.mutateAsync({
        id: file!._id,
        input: {
          process: columnId
        }
      });
      toggleFileMoving();
      onClose();
    } catch (e) {
      toggleFileMoving();
    }
  };
  const handleFileProcessedStatusChanged = async (): Promise<void> => {
    try {
      toggleFileProcessing();
      await updateFile.mutateAsync({
        id: file!._id,
        input: {
          isProcessed: !file!.isProcessed
        }
      });
      toggleFileProcessing();
    } catch (e) {
      toggleFileProcessing();
    }
  };
  useEffect(() => {
    if (isViewingFile && file) {
      const input: UpdateFileInput = {
        // skip null check to allow for unassigning agent
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        agent: file._agent?._id ?? null,
        client: file._client._id,
        isProcessed: file.isProcessed,
        process: file._process._id,
        products: file.products.map(product => ({
          ...pick(product, ['_id', 'metadata', 'product', 'quantity']),
          options: product.options.map(option => pick(option, ['_id', 'metadata', 'option', 'quantity']))
        })) as UpdateFileInput['products'],
        assignees: file._assignees.map(assignee => assignee._id),
        processPaths: file.processPaths.map(processPath => ({
          process: processPath._process._id,
          leadTime: processPath.leadTime
        })) as UpdateFileInput['processPaths'],
        counterConfigs: file.counterConfigs ? {
          counter: file.counterConfigs._counter._id
        } : undefined
      };
      updateUpdateFileInput(input);
      if (file.customFields) {
        setCustomFieldValues(file.customFields.reduce((acc, field) => ({
          ...acc,
          [field.customField]: field
        }), {}));
      }
    }
    return () => {
      if (isViewingFile) {
        resetTill();
      }
    };
  }, [isViewingFile, file]);
  return <Dialog fullWidth maxWidth="xl" scroll="paper" open={isViewingFile} data-sentry-element="Dialog" data-sentry-component="ViewFileModal" data-sentry-source-file="view-file-modal.tsx">
			{isCustomFieldsDialogOpen && <CustomFieldsDialog model={CUSTOM_FIELD_MODEL.FILE} onClose={closeCustomFieldsDialog} />}
			<Stack gap={3} p={3} sx={{
      height: '100dvh',
      overflow: 'auto'
    }} data-sentry-element="Stack" data-sentry-source-file="view-file-modal.tsx">
				{isLoading ? <>
						<Stack direction="row" justifyContent="space-between" spacing={1}>
							<Skeleton variant="rounded" height={40} sx={{
            flex: 1
          }} />
							<Skeleton variant="circular" height={40} width={40} />
						</Stack>
						<Box>
							<Skeleton variant="rounded" height={40} />
						</Box>
						<Box>
							<Grid container spacing={3}>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="circular" height={40} width={40} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12}>
									<Skeleton variant="rounded" height={240} sx={{
                flex: 1
              }} />
								</Grid>
							</Grid>
						</Box>
					</> : <>
						{isProcessPathsDialogOpen && <DefineProcessPathsDialog initialValues={till.updateFileInput?.processPaths || []} onDone={async values => {
          await updateFile.mutateAsync({
            id: file!._id,
            input: {
              processPaths: values as UpdateFileInput['processPaths']
            }
          });
          updateUpdateFileInput({
            processPaths: values as UpdateFileInput['processPaths']
          });
          closeProcessPathsDialog();
        }} onCancel={closeProcessPathsDialog} />}
						<Stack alignItems={{
          sm: 'center'
        }} direction={{
          xs: 'column-reverse',
          sm: 'row'
        }} justifyContent={{
          sm: 'space-between'
        }} spacing={1}>
							<div>
								{!file?.processPaths?.length && <FileStatus prefix="Move to" onChange={handleMoveFileToColumn} isLoading={isFileMoving} options={processOptions} value={file?._process?._id || processOptions[0]?.value} />}
								{!!file?.processPaths?.length && <LoadingButton variant="contained" endIcon={<SvgIcon>
												<ChevronRight />
											</SvgIcon>} disabled={!nextProcessId} loading={isFileMoving} onClick={() => handleMoveFileToColumn(nextProcessId!)}>
										Move to next process
									</LoadingButton>}
							</div>
							<Stack justifyContent="flex-end" alignItems="center" direction="row" spacing={1}>
								<LoadingButton color="success" variant={file?.isProcessed ? 'contained' : 'outlined'} startIcon={file?.isProcessed ? <SvgIcon>
												<Check />
											</SvgIcon> : null} loading={isFileProcessing} onClick={handleFileProcessedStatusChanged}>
									{file?.isProcessed ? 'Processed' : 'Mark processed'}
								</LoadingButton>

								<IconButton onClick={onClose}>
									<SvgIcon>
										<XIcon />
									</SvgIcon>
								</IconButton>
							</Stack>
						</Stack>
						<Box>
							<Stack spacing={2} direction="row">
								<TextField select label="Prefix" sx={{
              width: '150px'
            }} value={file?.counterConfigs?._counter?._id || ''} onChange={async e => {
              await updateFile.mutateAsync({
                id: file!._id,
                input: {
                  counterConfigs: {
                    counter: e.target.value
                  }
                }
              });
              updateUpdateFileInput({
                counterConfigs: {
                  counter: e.target.value
                }
              });
            }}>
									{counters.map(counter => <MenuItem key={counter._id} value={counter._id}>
											{counter.prefix}
										</MenuItem>)}
								</TextField>
								<OutlinedInput disabled fullWidth value={file?.counterConfigs?.index || ''} placeholder="Prefix index goes here" />
							</Stack>
							<FormHelperText>Changing prefix will update the current prefix index</FormHelperText>
							<Divider />
						</Box>
						<Grid container spacing={3}>
							<Grid container spacing={3} xs={12} md={6} height="fit-content">
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Last updated
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Typography variant="caption">
										{updateFile.isLoading ? 'Saving...' : $dayjs(file?.updatedAt).fromNow()}
									</Typography>
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Created by
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Avatar src={file?._user?.avatar || undefined} />
										<Stack>
											<Typography component="div" variant="caption">
												{file?._user?.firstName || 'N/A'}
											</Typography>
											<Typography variant="caption" color="text.secondary">
												<i>{$dayjs(file?.createdAt).fromNow()}</i>
											</Typography>
										</Stack>
									</Stack>
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Process paths
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									{!!file?.processPaths?.length && <Timeline sx={{
                m: 0,
                py: 0
              }}>
											{file.processPaths.slice(0, 3).map((path, index, current) => {
                  return <TimelineItem key={index} sx={{
                    '::before': {
                      content: 'unset'
                    }
                  }}>
														<TimelineSeparator>
															<TimelineDot color={path._process._id === file?._process._id ? 'primary' : 'grey'} />
															{index < 2 && !!current[index + 1] && <TimelineConnector />}
															{index === 2 && !!file.processPaths[3] && <TimelineConnector />}
														</TimelineSeparator>
														<TimelineContent>{path._process.name}</TimelineContent>
													</TimelineItem>;
                })}
										</Timeline>}
									<Button startIcon={<SvgIcon>
												<Route />
											</SvgIcon>} onClick={openProcessPathsDialog}>
										Define process paths
									</Button>
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Assignees
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Autocomplete multiple fullWidth disableCloseOnSelect options={users} value={users.filter(user => till.updateFileInput?.assignees?.includes(user._id))} isOptionEqualToValue={(option, value) => option._id === value._id} getOptionLabel={option => option.firstName} onChange={async (_, values) => {
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    assignees: values.map(user => user._id)
                  }
                });
                updateUpdateFileInput({
                  assignees: values.map(user => user._id)
                });
              }} renderInput={params => <TextField {...params} label="Users" />} renderTags={(value, getTagProps) => value.map((value, index) => <Chip {...getTagProps({
                index
              })} key={value._id} label={value.firstName} avatar={<Avatar src={value.avatar || undefined} />} />)} renderOption={(props, option) => <li {...props}>
												<Avatar src={option.avatar || undefined} />
												&nbsp;{option.firstName}
											</li>} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Client
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Autocomplete fullWidth options={clients} value={clients.find(client => client._id === till.updateFileInput?.client)} isOptionEqualToValue={(option, value) => option._id === value?._id} getOptionLabel={option => option.name} onChange={async (_, value) => {
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    client: value?._id
                  }
                });
                updateUpdateFileInput({
                  client: value?._id
                });
              }} renderInput={(params): JSX.Element => <TextField {...params} label="Clients" />} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Agent
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Autocomplete fullWidth options={agents} value={agents.find(agent => agent._id === till.updateFileInput?.agent)} isOptionEqualToValue={(option, value) => option._id === value?._id} getOptionLabel={option => option.name} onChange={async (_, value) => {
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    // skip null check to allow for unassigning agent
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    agent: value?._id ?? null
                  }
                });
                updateUpdateFileInput({
                  agent: value?._id
                });
              }} renderInput={(params): JSX.Element => <TextField {...params} label="Agents" />} />
								</Grid>
								{customFields.map(field => [<Grid xs={12} sm={4} key={`${field._id}-label`}>
										<Typography color="text.secondary" variant="caption">
											{field.name}
										</Typography>
									</Grid>, <Grid xs={12} sm={8} key={`${field._id}-input`}>
										<CustomFieldRenderer field={field} value={customFieldValues[field._id]} onChange={async value => {
                const updatedCustomFieldValues = {
                  ...customFieldValues,
                  [value.customField]: value
                };
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    customFields: Object.values(updatedCustomFieldValues)
                  }
                });
                setCustomFieldValues(updatedCustomFieldValues);
              }} />
									</Grid>])}
								<Grid xs={12} textAlign="right">
									<Button startIcon={<BarChartSquarePlus />} onClick={openCustomFieldsDialog}>
										Manage custom fields
									</Button>
								</Grid>
							</Grid>
							<Grid container spacing={3} xs={12} md={6} flexDirection="column">
								<Grid xs={12} textAlign="right">
									<Button onClick={() => {
                setTillIsOpen(true);
              }} startIcon={<SvgIcon>
												<Pencil01 />
											</SvgIcon>}>
										Update products
									</Button>
								</Grid>
								<Grid xs={12}>
									<ProductsTableSummary items={updateFileInputSummary.items} grandTotal={updateFileInputSummary.grandTotal} />
								</Grid>
							</Grid>
						</Grid>
					</>}
			</Stack>
		</Dialog>;
};