import { Button, Container, FormHelperText, IconButton, InputLabel, MenuItem, Popover, Select, SelectChangeEvent, Stack, Step, StepLabel, Stepper, SvgIcon, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AlarmClock, Trash01 } from '@untitled-ui/icons-react/build/esm';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Dialog } from '~/components/dialog';
import { usePopover } from '~/hooks/use-popover';
import { $dayjs } from '~/libs/dayjs';
import { useGetProcesses } from '~/queries/useGetProcesses';
import { FileProcessPath, Process } from '~/types/__generated/gql/graphql';
import { leadTimeInputLabels, leadTimeInputSchema } from '../process-card/process-form';
export type DefineProcessPathsDialogProps = {
  initialValues: Array<Pick<FileProcessPath, 'process' | 'leadTime'>>;
  onDone: (values: Array<Pick<FileProcessPath, 'process' | 'leadTime'>>) => void;
  onCancel: () => void;
};
export const DefineProcessPathsDialog: FC<DefineProcessPathsDialogProps> = ({
  initialValues,
  onDone,
  onCancel
}) => {
  const [values, setValues] = useState(initialValues);
  const {
    data: processes = []
  } = useGetProcesses();
  const options = processes.filter(process => values.every(value => value.process !== process._id));
  return <Dialog dialogProps={{
    maxWidth: 'md'
  }} title="Define process paths" cancelButtonProps={{
    onClick: onCancel,
    children: 'Cancel'
  }} actionButtonProps={{
    onClick: () => onDone(values),
    children: 'Done'
  }} content={<Stack mt={0.25} spacing={3}>
					<Stack>
						<Select key={options.map(o => o._id).join()} defaultValue="" fullWidth onChange={(e: SelectChangeEvent<string>) => {
        setValues(prev => [...prev, {
          process: e.target.value
        }]);
      }}>
							{options.map(option => <MenuItem key={option._id} value={option._id}>
									{option.name}
								</MenuItem>)}
						</Select>
						<FormHelperText>Select a process to add</FormHelperText>
					</Stack>
					<Stepper alternativeLabel activeStep={values.length - 1}>
						{values.map((value, index) => {
        const process = processes.find(process => process._id === value.process);
        if (!process) return null;
        return <Step key={process._id}>
									<StepLabel>
										<Stack alignItems="center" spacing={1}>
											<ProcessName process={process} value={value} onSave={leadTime => setValues(prev => prev.map((v, i) => i === index ? {
                ...v,
                leadTime
              } : v))} />
											<IconButton color="error" onClick={() => setValues(prev => prev.filter((_, i) => i !== index))}>
												<SvgIcon>
													<Trash01 />
												</SvgIcon>
											</IconButton>
										</Stack>
									</StepLabel>
								</Step>;
      })}
					</Stepper>
				</Stack>} data-sentry-element="Dialog" data-sentry-component="DefineProcessPathsDialog" data-sentry-source-file="define-process-paths-dialog.tsx" />;
};
const ProcessName: FC<{
  process: Pick<Process, '_id' | 'leadTime' | 'name'>;
  value: Pick<Process, 'leadTime'>;
  onSave: (value: string) => void;
}> = ({
  process,
  value,
  onSave
}) => {
  const popover = usePopover<HTMLButtonElement>();
  const form = useFormik<Yup.InferType<typeof leadTimeInputSchema>>({
    initialValues: {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    validationSchema: leadTimeInputSchema,
    onSubmit: values => {
      onSave($dayjs.duration(values).toISOString());
      popover.handleClose();
    }
  });
  useEffect(() => {
    const processLeadTime = value.leadTime ? $dayjs.duration(value.leadTime) : undefined;
    form.setValues({
      years: processLeadTime ? processLeadTime.years() : 0,
      months: processLeadTime ? processLeadTime.months() : 0,
      days: processLeadTime ? processLeadTime.days() : 0,
      hours: processLeadTime ? processLeadTime.hours() : 0,
      minutes: processLeadTime ? processLeadTime.minutes() : 0,
      seconds: processLeadTime ? processLeadTime.seconds() : 0
    });
  }, [value.leadTime]);
  return <Stack direction="row" alignItems="center" spacing={1} data-sentry-element="Stack" data-sentry-component="ProcessName" data-sentry-source-file="define-process-paths-dialog.tsx">
			{process.name}
			<IconButton color={value.leadTime && $dayjs.duration(value.leadTime).asMilliseconds() ? 'success' : 'default'} ref={popover.anchorRef} onClick={popover.handleToggle} data-sentry-element="IconButton" data-sentry-source-file="define-process-paths-dialog.tsx">
				<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="define-process-paths-dialog.tsx">
					<AlarmClock data-sentry-element="AlarmClock" data-sentry-source-file="define-process-paths-dialog.tsx" />
				</SvgIcon>
			</IconButton>
			<Popover anchorOrigin={{
      horizontal: 'center',
      vertical: 'bottom'
    }} transformOrigin={{
      horizontal: 'center',
      vertical: 'top'
    }} anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} data-sentry-element="Popover" data-sentry-source-file="define-process-paths-dialog.tsx">
				<Container maxWidth="sm" sx={{
        p: '0 !important'
      }} data-sentry-element="Container" data-sentry-source-file="define-process-paths-dialog.tsx">
					<Stack p={3} spacing={3} component="form" onSubmit={form.handleSubmit} data-sentry-element="Stack" data-sentry-source-file="define-process-paths-dialog.tsx">
						<Stack data-sentry-element="Stack" data-sentry-source-file="define-process-paths-dialog.tsx">
							<InputLabel data-sentry-element="InputLabel" data-sentry-source-file="define-process-paths-dialog.tsx">Lead Time</InputLabel>
							<Grid container spacing={1} data-sentry-element="Grid" data-sentry-source-file="define-process-paths-dialog.tsx">
								{leadTimeInputLabels.map(({
                field,
                label
              }) => <Grid key={field} xs={4} md={2}>
										<TextField type="number" autoFocus={field === 'years'} label={label} {...form.getFieldProps(field)} error={!!form.errors[field]} helperText={form.errors[field]} />
									</Grid>)}
							</Grid>
						</Stack>
						<Stack direction="row" justifyContent="flex-end" data-sentry-element="Stack" data-sentry-source-file="define-process-paths-dialog.tsx">
							<Button type="submit" variant="contained" data-sentry-element="Button" data-sentry-source-file="define-process-paths-dialog.tsx">
								Save
							</Button>
						</Stack>
					</Stack>
				</Container>
			</Popover>
		</Stack>;
};