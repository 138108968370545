import { useQuery } from '@tanstack/react-query';

import { apiGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { GetProductsQuery } from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	query GetProducts {
		getProducts {
			name
			description
			category
			type
			originalPrice
			originalPriceAddons {
				vat {
					percentage
					amount
				}
				serviceCharge {
					percentage
					amount
				}
			}
			listedPrice
			options
			configuredOptionGroups {
				isRequired
				minimumSelect
				maximumSelect
				optionGroup
			}
			companyIssuedId
			_id
			createdAt
			updatedAt

			_category {
				_id
				name
			}
		}
	}
`;

export type GetProductsResults = GetProductsQuery['getProducts'];

export const getProductsQueryKey = () => ['getProducts'];

export const useGetProducts = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getProductsQueryKey(),
		queryFn: async () => apiGqlClient.request(graphql(query)).then((res) => res.getProducts),
	});
};

export const useGetProductsCache = () => {
	return useGetProducts(false);
};
