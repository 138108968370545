import { Avatar, AvatarGroup, Chip } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { AlarmClock, Bank, CheckCircleBroken, CoinsHand, Route } from '@untitled-ui/icons-react/build/esm';
import { FC, forwardRef, useMemo } from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import { useAppRouter } from '~/hooks/use-app-router';
import { $dayjs } from '~/libs/dayjs';
import { useGetProcessesCache } from '~/queries/useGetProcesses';
import { File } from '~/types/__generated/gql/graphql';
interface FileCardProps {
  dragging: boolean;
  file: File;
}
export const FileCard = forwardRef<HTMLDivElement, FileCardProps>(function FileCard({
  file,
  dragging
}, ref) {
  const {
    goToFileDetailsQuery
  } = useAppRouter();
  const onOpen = () => goToFileDetailsQuery(file._id);
  return <Card elevation={dragging ? 8 : 1} onClick={onOpen} ref={ref} sx={{
    backgroundColor: theme => theme.palette.mode === 'dark' ? 'neutral.800' : 'background.paper',
    ...(dragging && {
      backgroundColor: theme => theme.palette.mode === 'dark' ? 'neutral.800' : 'background.paper'
    }),
    p: 3,
    userSelect: 'none',
    '&:hover': {
      backgroundColor: theme => theme.palette.mode === 'dark' ? 'neutral.700' : 'neutral.50'
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 1
    }
  }}>
			{!!file.counterConfigs && <Typography variant="subtitle1">
					<b>{file.counterConfigs._counter.prefix}</b>
					&nbsp;
					{file.counterConfigs.index}
				</Typography>}
			<Stack spacing={2} sx={{
      mt: 2
    }}>
				<Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
					<Chip icon={<Bank />} label={file._client.name} />
					{!!file._agent && <Chip icon={<CoinsHand />} label={file._agent.name} />}
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1}>
					{!!file.processPaths.length && <SvgIcon>
							<Route />
						</SvgIcon>}
					{!!file._assignees.length && <AvatarGroup max={3}>
							{file._assignees.map(assignee => <Avatar key={assignee._id} src={assignee.avatar || undefined} />)}
						</AvatarGroup>}
					<div style={{
          flexGrow: 1
        }} />
					{file.isProcessed && <SvgIcon color="success">
							<CheckCircleBroken />
						</SvgIcon>}
				</Stack>
				<Timer file={file} />
			</Stack>
		</Card>;
});
export const Timer: FC<{
  file: File;
}> = ({
  file
}) => {
  const {
    data: processes = []
  } = useGetProcessesCache();
  const matchingProcesspath = useMemo(() => {
    return file.processPaths.find(processPath => processPath.process === file._process._id);
  }, [file]);
  const matchingProcess = useMemo(() => {
    return processes.find(process => process._id === file._process._id);
  }, [processes, file]);
  if (matchingProcesspath?.leadTime && $dayjs.duration(matchingProcesspath.leadTime).asMilliseconds()) {
    return <Countdown overtime date={$dayjs(file.processLastUpdatedAt).add($dayjs.duration(matchingProcesspath.leadTime)).toDate()} renderer={CountdownRenderer} />;
  }
  if (matchingProcess?.leadTime && $dayjs.duration(matchingProcess.leadTime).asMilliseconds()) {
    return <Countdown overtime date={$dayjs(file.processLastUpdatedAt).add($dayjs.duration(matchingProcess.leadTime)).toDate()} renderer={CountdownRenderer} />;
  }
  return null;
};
const CountdownRenderer: CountdownRendererFn = ({
  props,
  completed
}) => {
  const diff = $dayjs.duration($dayjs(props.date).diff($dayjs()));
  const diffAbs = {
    years: Math.abs(diff.years()),
    months: Math.abs(diff.months()),
    days: Math.abs(diff.days()),
    hours: Math.abs(diff.hours()),
    minutes: Math.abs(diff.minutes()),
    seconds: Math.abs(diff.seconds())
  };
  const Content = () => {
    if (diffAbs.years > 0) {
      return `${diffAbs.years}Y ${diffAbs.months}M ${diffAbs.days}D`;
    }
    if (diffAbs.months > 0) {
      return `${diffAbs.months}M ${diffAbs.days}D ${diffAbs.hours}h`;
    }
    if (diffAbs.days > 0) {
      return `${diffAbs.days}D ${diffAbs.hours}h ${diffAbs.minutes}m`;
    }
    if (diffAbs.hours > 0) {
      return `${diffAbs.hours}h ${diffAbs.minutes}m ${diffAbs.seconds}s`;
    }
    return `${diffAbs.minutes}m ${diffAbs.seconds}s`;
  };
  return <Stack spacing={1} direction="row" alignItems="center" sx={{
    color: t => completed ? t.palette.error.main : t.palette.text.primary
  }} data-sentry-element="Stack" data-sentry-component="CountdownRenderer" data-sentry-source-file="file-card.tsx">
			<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="file-card.tsx">
				<AlarmClock data-sentry-element="AlarmClock" data-sentry-source-file="file-card.tsx" />
			</SvgIcon>
			<Typography variant="caption" data-sentry-element="Typography" data-sentry-source-file="file-card.tsx">
				<Content data-sentry-element="Content" data-sentry-source-file="file-card.tsx" />
			</Typography>
		</Stack>;
};