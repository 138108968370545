import { useQuery } from '@tanstack/react-query';

import { useAppRouter } from '~/hooks/use-app-router';
import { apiGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { GetFileByIdQuery } from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	query GetFileById($id: ObjectID!) {
		getFileById(id: $id) {
			customFields {
				customField
				textValue
				selectValues
				dateTimeValue
			}
			isProcessed
			products {
				product
				metadata {
					originalPrice
					originalPriceAddons {
						vat {
							percentage
							amount
						}
						serviceCharge {
							percentage
							amount
						}
					}
					listedPrice
				}
				quantity
				options {
					option
					metadata {
						originalPrice
						originalPriceAddons {
							vat {
								percentage
								amount
							}
							serviceCharge {
								percentage
								amount
							}
						}
						listedPrice
					}
					quantity
					_id
					createdAt
					updatedAt

					_option {
						_id
						name
					}
				}
				_id
				createdAt
				updatedAt

				_product {
					_id
					name
					description
					category
					type
				}
			}
			sortingIndex
			processPaths {
				leadTime
				_process {
					name
					_id
					createdAt
					updatedAt
					leadTime
				}
			}
			processLastUpdatedAt
			counterConfigs {
				index

				_counter {
					_id
					prefix
				}
			}
			_id
			createdAt
			updatedAt

			_process {
				name
				_id
				createdAt
				updatedAt
			}
			_user {
				firstName
				lastName
				avatar
				_id
				createdAt
				updatedAt
			}
			_client {
				name
				_id
				createdAt
				updatedAt
			}
			_agent {
				name
				_id
				createdAt
				updatedAt
			}
			_assignees {
				firstName
				lastName
				avatar
				_id
				createdAt
				updatedAt
			}
		}
	}
`;

export type GetFileByIdResults = GetFileByIdQuery['getFileById'];

export const getFileByIdQueryKey = (id: string) => ['getFileById', id];

export const useGetFileById = (id?: string, enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? !!id,
		queryKey: getFileByIdQueryKey(id!),
		queryFn: async () => apiGqlClient.request(graphql(query), { id: id! }).then((res) => res.getFileById),
	});
};

export const useGetFileByIdCache = (id?: string) => {
	return useGetFileById(id, false);
};

export const useGetCurrentFile = () => {
	const { appQuery } = useAppRouter();
	return useGetFileById(appQuery.fileId);
};

export const useGetCurrentFileCache = () => {
	const { appQuery } = useAppRouter();
	return useGetFileByIdCache(appQuery.fileId);
};
